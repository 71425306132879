import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackNavigation, Button, Typography } from 'src/components/common';
import {
  ApplicantDetails,
  ApplicationFormStep,
  ApplyProgramProgramDetails,
  NavigationModal,
  TApplicationDetailsFormValues,
  TApplicationFormStepSectionItem,
  TopNavigation
} from 'src/components/company/programs/applyToProgramExternal';
import CompanyInfoCard from 'src/components/company/companyinfo/CompanyInfoCard';
import { colors } from 'src/constants';
import { StorageUtils } from 'src/utils'
import {
  TUploadApplicationFileAction
} from 'src/services/apiEndpoint.types.ts';
import { useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { createExternalApplication } from 'src/store/externalApplication/externalApplication.slice';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import ApplicationSuccessModal, {
  applicationSuccessModalId
} from 'src/components/company/programs/applyToProgramExternal/applicationSuccessModal/ApplicationSuccessModal';
import { ProgramClosed } from 'src/components/company/programs/applyToProgram';
import {
  getProgramForExternalUser,
  getUploadApplicationFileExternalUser
} from 'src/store/externalProgram/externalProgram.slice';

import './applyToProgramExternal.scss';
import BootstrapModal from 'src/components/common/BootstrapModal/BootstrapModal';
import { httpRequest } from 'src/services/commonApis';
import DraftDeleteModal from 'src/components/company/programs/applyToProgramExternal/DraftDeleteModal/DraftDeleteModal';

const ApplyToProgramExternal = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const ApplicationDraft: any = StorageUtils.get('ApplicationDraft');
  const data = useAppSelector((store: any) => store.externalApplicationSlice);
  const user = data?.user || ''
  const token = data?.token || ''
  const programData = useAppSelector((store: any) => store.externalProgramSlice);
  const viewProgram = programData?.viewProgram
  const viewCompany = programData?.viewProgram.company
  const modalId: string = useAppSelector((store: any) => store.common.modalId);
  const { isAuthorized } = useAppSelector((store) => store.auth);
  const [show, setShow] = useState<boolean>(false);
  const [resourceData, setResourceData] = useState<any>([])
  // React hooks
  const [applyProgramStep, setApplyProgramStep] = useState<number>(0);
  const [applicantDetails, setApplicantDetails] = useState<TApplicationDetailsFormValues>({
    name: '',
    email: ''
  });
  const [applicantSectionResponses, setApplicantSectionResponses] = useState<
    { sectionTitle: string; sectionItems: TApplicationFormStepSectionItem[] }[]
  >(viewProgram?.applicationForm?.sections);
  const [applicationFiles, setApplicationFiles] = useState<any>({});
  const [isDraft, setIsDraft] = useState<boolean>(false)
  // Constants
  const isApplicationSuccessModalOpen = modalId === applicationSuccessModalId;
  const programId: string = paramValue({ paramName: 'id' }) as string;

  const handleClose = () => setShow(false)
  const handleGoBack = () => {
    if (applyProgramStep > 0) {
      setApplyProgramStep((prev) => prev - 1);
    } else {
      navigate('/', { replace: true });
    }
  };

  const handleNext = () => {
    setApplyProgramStep((prev) => {
      if (prev + 1 < steps?.length) {
        return prev + 1;
      }
      return prev;
    });
  };

  const goToApplicationHome = () => {
    // localStorage.removeItem('applicationFormSections')
    setApplicantSectionResponses(viewProgram?.applicationForm?.sections)
    StorageUtils.remove('ApplicationDraft')
    setApplyProgramStep(0);
  };

  const updateApplicantDetails = ({ name, email, mobile }: TApplicationDetailsFormValues) => {
    setApplicantDetails({ name, email, mobile });
  };
  const handleGetProgram = () => {
    const payload: any = {
      programId: programId
    };
    if (programId && programId !== '0' && programId !== '') {
      dispatch(getProgramForExternalUser(payload))
    }
  };
  const steps = [<ApplyProgramProgramDetails key="applyProgramProgramDetails" handleGetProgram={handleGetProgram} />];

  const updateApplicantSectionResponse = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: any;
  }) => {
    setApplicantSectionResponses((prev) => {
      const currentSections = JSON.parse(JSON.stringify(prev));

      currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };

      return currentSections;
    });

    setApplicationFiles((prev: any) => {
      const output = { ...prev, ...selectedFiles };

      return output;
    });

    handleNext();
  };

  const handleApplyToProgram = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: any;
  }) => {
    const currentSections = JSON.parse(JSON.stringify(applicantSectionResponses));

    currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };

    const combinedFiles = { ...applicationFiles, ...selectedFiles };
    setIsDraft(false)
    const payload: any = {
      programId: programId,
      userId: user?.id,
      name: applicantDetails?.name,
      email: applicantDetails?.email,
      mobile: applicantDetails?.mobile,
      draftId: ApplicationDraft?.id,
      response: { sections: currentSections },
      authorizationToken: token
    };
    dispatch(createExternalApplication(payload)).then((response: any) => {
      if (response?.status === 200) {
        const applicationId = response?.data?.application?.id || 0;
        if (ApplicationDraft?.id) {
          StorageUtils.remove('ApplicationDraft')
        }
        Object.keys(combinedFiles).forEach((key) => {
          try {
            const files = combinedFiles[key];

            files.forEach((file: any) => {
              const fileBytes: any = file.data
              if (fileBytes.byteLength > 0) {
                const payload: TUploadApplicationFileAction = {
                  programId: programId,
                  applicationId,
                  id: file?.key,
                  fileName: file?.fileName,
                  data: file?.data || ''
                };

                dispatch(getUploadApplicationFileExternalUser(payload));
              }
            });
          } catch (e) {
            console.log(e);
          }
        });

        dispatch(assignModalId(applicationSuccessModalId));
      }
    });
  };
  const handleDraft = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: any;
  }) => {
    const currentSections = JSON.parse(JSON.stringify(applicantSectionResponses));
    currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };
    const combinedFiles = { ...applicationFiles, ...selectedFiles };
    const payload: any = {
      programId: programId,
      userId: user?.id,
      name: applicantDetails?.name,
      email: applicantDetails?.email,
      mobile: applicantDetails?.mobile,
      draftId: ApplicationDraft?.id,
      Program: viewProgram,
      draft: true,
      response: { sections: currentSections },
      authorizationToken: token
    };
    dispatch(createExternalApplication(payload)).then((response) => {
      if (ApplicationDraft?.id) {
        StorageUtils.remove('ApplicationDraft')
      }
      setIsDraft(true)
      dispatch(assignModalId(applicationSuccessModalId));
      const applicationId = response?.data?.application?.id || '';

      Object.keys(combinedFiles).forEach((key) => {
        try {
          const files = combinedFiles[key];

          files.forEach((file) => {
            const payload: TUploadApplicationFileAction = {
              programId: programId,
              applicationId,
              id: file?.key,
              fileName: file?.fileName,
              data: file?.data || ''
            };

            dispatch(getUploadApplicationFileExternalUser(payload));
          });
        } catch (e) {
          console.log(e);
        }
      });
    });
  };

  try {
    applicantSectionResponses?.forEach((section, index) => {
      const { sectionTitle, sectionItems } = section;

      const totalSteps: number = applicantSectionResponses?.length;

      const finalStep: boolean = totalSteps === index + 1;

      steps.push(
        <ApplicationFormStep
          sectionIndex={index}
          key={`${sectionTitle}-${index}`}
          sectionTitle={sectionTitle}
          sectionItems={sectionItems}
          handleNext={handleNext}
          handleDraft={handleDraft}
          finalStep={finalStep}
          updateApplicantSectionResponse={updateApplicantSectionResponse}
          handleApplyToProgram={handleApplyToProgram}
          goToApplicationHome={goToApplicationHome}
        />
      );
    });
  } catch { }

  async function getResources() {
    const response = await httpRequest(`resource?programId=${programId}`, 'GET', null, 'json', 'program');
    if (response.body?.length > 0) {
      setResourceData(response.body)
    }
  }

  const handleCloseApplicationSuccessModal = () => {
    dispatch(resetModalId());
    setApplyProgramStep(0);
  };

  const navigateToInternalApplication = () => {
    if (isAuthorized) {
      StorageUtils.remove('ApplicationDraft')
      navigate(`/apply-to-program?id=${programId}`);
    }
  };
  useEffect(() => {
    navigateToInternalApplication();
  }, [isAuthorized]);

  useEffect(() => {
    if (programId && programId !== '0' && programId !== '') {
      handleGetProgram();
    }
  }, [window.location.search, programId]);

  useEffect(() => {
    if (!StorageUtils.get('ApplicationDraft')) {
      setApplicantSectionResponses(viewProgram?.applicationForm?.sections);
    }
  }, [JSON.stringify(viewProgram?.applicationForm?.sections)]);

  useEffect(() => {
    if (programId && programId !== '0' && programId !== '') {
      getResources()
    }
  }, [programId])
  useEffect(() => {
    if (ApplicationDraft) {
      console.log(ApplicationDraft?.response?.sections, 'ApplicationDraft?.response?.sections')
      setApplicantSectionResponses(ApplicationDraft?.response?.sections);
    }
  }, [])
  console.log(applyProgramStep, 'applyProgramStep');
  return (
    <div className="apply-to-program-external">
      <TopNavigation />
      <div className="apply-to-program-external__body">
        {applyProgramStep !== 1 && <BackNavigation text={applyProgramStep === 0 ? "Back to home" : 'Back'} onClick={handleGoBack} />}
        <div className="view-company__body__content__body">
          <CompanyInfoCard
            name={viewCompany.name}
            type="{type}"
            introduction={viewCompany.introduction}
            website="{website}"
            contactUsUrl="{contactUsUrl}"
            profilePic={viewProgram?.company?.profilePic}
            dropdownItems={[]}
            programBanner={viewProgram?.banner}
          />
        </div>
        <div className="apply-to-program-external__body__step">
          <div className="apply-to-program-external__body__step__component">
            <div className="apply-to-program-external__body__step__component__header">
              <Typography
                variant="subHeading-2"
                fontWeight="semiBold"
                style={{ color: colors.neutral[1100] }}
              >
                {applyProgramStep === 0 ? viewProgram?.title : 'Application form'}
              </Typography>
              {resourceData?.length > 0 && applyProgramStep === 1 && <div className='d-flex gap-2'>
                <Button size="extraSmall" variant="secondary" onClick={() => setShow(true)}>
                  View resources
                </Button>
              </div>}
            </div>
            {show && <BootstrapModal show={show} handleClose={handleClose} title={'resources'} type='resources' resourceData={resourceData} />}
            {steps[applyProgramStep]}
          </div>
          {applyProgramStep === 0 && viewProgram?.status !== 'Closed' && (
            <ApplicantDetails
              applicantDetails={applicantDetails}
              updateApplicantDetails={updateApplicantDetails}
              handleNext={handleNext}
            />
          )}{' '}
          {applyProgramStep === 0 && viewProgram?.status === 'Closed' && (
            <ProgramClosed
              title={viewProgram?.title}
              endDate={viewProgram?.endDate}
              companyName={viewProgram?.company?.name}
            />
          )}
        </div>
      </div>
      <DraftDeleteModal goToApplicationHome={goToApplicationHome} />
      {/* <NavigationModal /> */}
      {isApplicationSuccessModalOpen && (
        <ApplicationSuccessModal
          isModalOpen={isApplicationSuccessModalOpen}
          handleCloseModal={handleCloseApplicationSuccessModal}
          ApplicationDraft={isDraft}

        />
      )}
    </div>
  );
};

export default ApplyToProgramExternal;
