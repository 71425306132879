import { AppDispatch } from './../store';
import { httpRequest } from '../../services/commonApis';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUploadApplicationFileAction, TUploadApplicationFileResponse, TUploadFileToS3Request } from 'src/services/apiEndpoint.types.ts';
import { errorMessages } from 'src/constants';

import { initialState } from './cohort.slice.initialState';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';
import { TDashboard } from 'src/services/apiEndpoint.types.ts/cohort/Dashboard.types';
import { TListPayload, TSearchCohort } from './cohort.type';
import { TCreateTeam } from 'src/services/apiEndpoint.types.ts/cohort/cohortOnBoard.types';
import { start } from 'repl';
import { keyboardImplementationWrapper } from '@testing-library/user-event/dist/keyboard';
import { APIResponse } from 'src/services/base.api.service';
import { CoohortApiServices } from 'src/services/cohort.api.service';
import { uploadFileToS3 } from '../program/program.slice';
export const cohortSlice = createSlice({
  name: 'cohort',
  initialState,
  reducers: {
    checkCohortAccess: (state, action) => {
      state.isAccess = action.payload
    },
    cohortLoadingStart: (state: any) => {
      state.cohortLoading = true
    },
    cohortLoadingEnd: (state: any) => {
      state.cohortLoading = false
    },
    globalLoadingStart: (state: any, action) => {
      state.globalLoading = action.payload
    },
    globalLoadingEnd: (state: any) => {
      state.globalLoading = ''
    },

    dashboardListSuccess: (state, action: PayloadAction<any>) => {
      const copydashboard: TDashboard | null = JSON.parse(JSON.stringify(action.payload)) || null;
      state.dashboardData = copydashboard;
    },
    cohortListSuccess: (state, action: PayloadAction<any>) => {
      const currentCohort: any[] = JSON.parse(JSON.stringify(action.payload)) || [];
      state.cohorts = currentCohort;
    },
    cohortListError: () => { },
    incrementProgramsPage: (state, action: PayloadAction<number>) => {
      state.cohortPageNumber = state.cohortPageNumber + action.payload;
    },
    resetCohort: (state) => {
      state.cohortPageNumber = 1;
      state.moreCohortsLeft = true;
      state.cohorts = [];
    },

    // View program
    getCohortSuccess: (state, action: PayloadAction<any>) => {
      const cohortData: any = action.payload;

      state.viewCohort = cohortData;
    },
    getCohortError: () => { },
    resetViewCohort: (state) => {
      state.viewCohort = initialState.viewCohort;
    },
    searchedTeamList: (state, action) => {
      state.searchedTeam = action.payload
    },
    resetTeamSearch: (state) => {
      state.searchedTeam = []
    },
    searchedMentorList: (state, action) => {
      state.searchedMentor = action.payload
    },
    resetMentorSearch: (state) => {
      state.searchedMentor = []
    },
    getCohortTeamList: (state, action: PayloadAction<any>) => {
      const teamData: any = action.payload;
      state.cohortTeam = teamData;
    },
    getCohortTeamSuccess: (state, action: PayloadAction<any>) => {
      const teamData: any = action.payload;
      state.viewCohortTeam = teamData;
    },
    getMilestoneDataSuccess: (state, action: PayloadAction<any>) => {
      const milestoneData: any = action.payload;
      state.Milestone = milestoneData;
    },
    resetMilestoneDataSuccess: (state) => {
      state.Milestone = [];
    },
    changeCohortModalState: (state, action: PayloadAction<any>) => {
      state.isTeamModalOpen = action.payload
    },
    investmentTrackingSuccess: (state, action: PayloadAction<any>) => {
      const investmentTrackingData: any = action.payload;
      state.investmentTrackingData = investmentTrackingData;
    },
    changeCohortSubmittedApplicationModal: (state, action: PayloadAction<any>) => {
      const cohortSubmittedApplicationModal: any = action.payload;
      state.cohortSubmittedApplicationModal = cohortSubmittedApplicationModal;
    },
    cohortResourcesSuccess: (state, action) => {
      const copyResources = JSON.parse(JSON.stringify(action.payload))
      state.cohortResouces = copyResources
    },
    resetcohortResources: (state) => {
      state.cohortResouces = []
    },
    assinedMentorSuccess: (state, action) => {
      state.assignedMentors = action.payload
    },
    resetAssignedMentor: (state) => {
      state.assignedMentors = []
    },
    changeInvestmentModalState: (state, action) => {
      state.isInvestmentModal = action.payload
    },
    changeUpdateTaskModalState: (state, action) => {
      console.log(action.payload, "action.payload")
      state.isUpdateTaskModal = action.payload
    },
    viewUserCohortSuccess: (state, action) => {
      state.viewUserCohort = action.payload
    },
    getReportsSuccess: (state, action) => {
      state.reportResources = action.payload
    },
    clearReports: (state) => {
      state.reportResources = []
    },
    changeOptionModalState: (state, action) => {
      state.optionMenu = action.payload
    },
    getEventSuccess: (state, action) => {
      state.events = action.payload
    },
    viewEventSuccess: (state, action) => {
      state.viewEvent = action.payload
    },
    getSurveySuccess: (state, action) => {
      state.serveysReports = action.payload
    },
    getPublicSurveySuccess: (state, action) => {
      state.publicServey = action.payload
    },
    getPublicEventSuccess: (state, action) => {
      state.publicEvent = action.payload
    },
    viewSurveySuccess: (state, action) => {
      state.viewSurvey = action.payload
    },
    viewSurveyResponseSuccess: (state, action) => {
      state.surveyResponseList = action.payload
    },
    viewResponseStateChange: (state, action) => {
      state.isViewResponse = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  checkCohortAccess,
  globalLoadingStart,
  globalLoadingEnd,
  dashboardListSuccess,
  cohortListSuccess,
  cohortListError,
  incrementProgramsPage,
  resetCohort,
  getCohortSuccess,
  resetViewCohort,
  searchedTeamList,
  resetTeamSearch,
  cohortLoadingStart,
  cohortLoadingEnd,
  getCohortTeamList,
  getCohortTeamSuccess,
  getMilestoneDataSuccess,
  changeUpdateTaskModalState,
  searchedMentorList,
  resetMentorSearch,
  resetMilestoneDataSuccess,
  changeCohortModalState,
  investmentTrackingSuccess,
  cohortResourcesSuccess,
  resetcohortResources,
  assinedMentorSuccess,
  resetAssignedMentor,
  changeInvestmentModalState,
  viewUserCohortSuccess,
  getReportsSuccess,
  clearReports,
  changeOptionModalState,
  changeCohortSubmittedApplicationModal,
  getEventSuccess,
  getSurveySuccess,
  viewEventSuccess,
  viewSurveySuccess,
  viewSurveyResponseSuccess,
  getPublicSurveySuccess,
  getPublicEventSuccess,
  viewResponseStateChange
} = cohortSlice.actions;

export default cohortSlice.reducer;
const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const createCohort = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('cohort'));
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createCohort(payload);
    console.log(data, 'dataaaaaaaaaaaaaa');

    if (status === 200 || data.status) {
      dispatch(cohortList({ incubatorId: payload.incubatorId }))
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success',
          navigateTo: `/view-cohort?id=${data?.data?.id}&companyId=${payload.incubatorId}&tab=Dashboard`
        })
      );
      return { status: true }
    } else {
      throw new Error('something went wrong')
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
};
export const updateCohort = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('cohort'));
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().updateCohort(payload);
    if (status === 200 || data.status) {
      dispatch(cohortList({ incubatorId: payload.incubatorId }))
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success'
        })
      );
      return { status: true }
    } else {
      throw new Error('something went wrong')
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
};

export const getDashboard = ({ incubatorId, cohortId }: { incubatorId: string, cohortId: string }) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getCohortDashboard({ incubatorId, cohortId });
    if (status === 200) {
      console.log(data.data, 'dataaaaa');
      dispatch(dashboardListSuccess(data.data));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(stopLoading())
  }
};
export const cohortList = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('cohort'));
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getCohortList({ incubatorId: payload.incubatorId });

    if (status === 200) {
      dispatch(checkCohortAccess(true))
      dispatch(cohortListSuccess(data.data));
    }
  } catch (err: any) {
    if (err?.response?.data?.message === 'You done have access to cohort') {
      dispatch(checkCohortAccess(false))
      dispatch(cohortListSuccess([]));
      dispatch(
        openPopup({
          popupMessage: 'You do not have access to cohort',
          popupType: 'error'
        })
      );
    }
    console.log(err.message);
  } finally {
    dispatch(globalLoadingEnd())
  }
};

export const getCohort = ({ cohortId, incubatorId }: { cohortId: string, incubatorId: string }) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getCohortList({ incubatorId, id: cohortId });
    if (status === 200) {
      dispatch(getCohortSuccess(data.data[0] || null));
    }
  } catch (err: any) {
    if (err.response.data.message === "Forbidden") {
      window.location.href = '/not-found';
      return
    }
    console.log(err.message);
  } finally {
    dispatch(stopLoading())
  }
  // const path = `cohort/get?id=${cohortId}&incubatorId=${incubatorId}`
  // const response = await httpRequest(path, 'GET', null, 'json', 'cohort')
  // console.log(response, "resonsee")
  // if (response.message === "Forbidden") {
  //   window.location.href = '/not-found'
  // } else {
  //   if (response?.status) {
  //     dispatch(getCohortSuccess(response?.data[0] || null));
  //   } else {
  //     dispatch(getCohortSuccess(null));
  //   }
  //   dispatch(stopLoading());
  // }
}
export const cohortTeamSearch = (payload: TSearchCohort) => async (dispatch: any) => {
  const { key, page, pageSize, companyId } = payload
  dispatch(cohortLoadingStart());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getCohortTeamSearch({ key, incubatorId: companyId });
    if (status === 200) {
      dispatch(searchedTeamList(data?.data?.users || []))
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(cohortLoadingEnd());
  }
}
export const assignCohortMentor = (payload: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().assignMentor(payload);
    if (data.status) {
      dispatch(cohortAssignedMentor({ cohortId: payload.cohortId }))
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success'
        })
      );
    }
    return { status: data.status }
  } catch (err: any) {
    console.log(err.message);
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
    return { status: false }
  } finally {
    dispatch(stopLoading());
  }
}
export const handleUssignCohortMentor = (payload: { teamId: string, cohortId: string, mentorId: string }) => async (dispatch: any) => {
  const { cohortId, ...body } = payload
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().unassignMentor(body);
    if (data.status) {
      return { status: data.status, message: data.message }
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
    return { status: false, message: err?.response?.data?.message || err?.message }
  }
}
export const cohortMentorSearch = ({ key, incubatorId }: { key: string, incubatorId: string }) => async (dispatch: any) => {
  dispatch(globalLoadingStart('search-mentor'));
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().mentorSearch({ mentorName: key, incubatorId });
    if (status === 200) {
      dispatch(searchedMentorList(data?.data || []));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(globalLoadingEnd());
  }
}
export const createCohortTeam = (payload: TCreateTeam) => async (dispatch: AppDispatch) => {
  const { path, ...body } = payload
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createTeam(body);
    if (data.status || status === 200) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success',
          navigateTo: path
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
  // const response = await httpRequest('cohort/team/create', 'POST', data, 'json', 'cohort');
  // if (response?.status) {
  //   dispatch(
  //     openPopup({
  //       popupMessage: response.message,
  //       popupType: 'success',
  //       navigateTo: path
  //     })
  //   );
  // } else {
  //   dispatch(
  //     openPopup({
  //       popupMessage: response.message,
  //       popupType: 'error'
  //     })
  //   );
  // }
  // dispatch(stopLoading());
}
export const updateCohortTeam = (payload: TCreateTeam) => async (dispatch: AppDispatch) => {
  const { path, ...body } = payload
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().updateTeam(body);
    if (data.status || status === 200) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success',
          navigateTo: path
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
  // const response = await httpRequest('cohort/team/update', 'PATCH', data, 'json', 'cohort');
  // if (response?.status) {
  //   dispatch(
  //     openPopup({
  //       popupMessage: response.message,
  //       popupType: 'success',
  //       navigateTo: path
  //     })
  //   );
  // } else {
  //   dispatch(
  //     openPopup({
  //       popupMessage: response.message,
  //       popupType: 'error'
  //     })
  //   );
  // }
  // dispatch(stopLoading());
}
export const cohortTeamList = ({ cohortId, incubatorId }: TListPayload) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('mentor-team'));
  dispatch(getCohortTeamSuccess(null));

  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getTeams({ cohortId, incubatorId });
    if (data.status || status === 200) {
      dispatch(getCohortTeamList(data?.data));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(globalLoadingEnd());
  }

  // const path = `cohort/team/get?cohortId=${cohortId}&incubatorId=${incubatorId}`
  // const response = await httpRequest(path, 'GET', null, 'json', 'cohort')
  // if (response?.status) {
  //   dispatch(getCohortTeamList(response?.data));
  // } else {
  //   dispatch(getCohortTeamList([]));
  // }
  // dispatch(globalLoadingEnd());
};
export const viewcohortTeam = (payload: { cohortId: string; teamId: string, incubatorId: string }) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  dispatch(getCohortTeamSuccess(null));
  const { cohortId, teamId: id, incubatorId } = payload
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getTeams({ cohortId, incubatorId, id });
    if (data.status || status === 200) {
      console.log(data.data[0], 'dataaaaaaaaaa');

      dispatch(getCohortTeamSuccess(data?.data[0] || null));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(stopLoading());
  }
};
export const removeTeam = (payload: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().removeTeam(payload);
    if (data.status || status === 200) {
      dispatch(cohortTeamList({ cohortId: payload.cohortId, incubatorId: payload.incubatorId }))
      dispatch(
        openPopup({
          popupMessage: data?.message,
          popupType: 'success'
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
}
export const removeTeamMember = (payload: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().unassignTeamMember(payload);
    if (data.status || status === 200) {
      const Teampayload = { cohortId: payload.cohortId, teamId: payload.teamId, incubatorId: payload.incubatorId }
      dispatch(viewcohortTeam(Teampayload))
      dispatch(
        openPopup({
          popupMessage: data?.message,
          popupType: 'success'
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
}
export const viewmycohortTeam = (payload: { teamId: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('team'));

  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getMyTeam({ id: payload.teamId });
    if (data.status || status === 200) {
      dispatch(getCohortTeamSuccess(data?.data[0] || null));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(globalLoadingEnd());
  }
};

export const createCohortMilestone = (payload: any) => async (dispatch: AppDispatch) => {
  const { ...payloadData } = payload

  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createMilestone(payloadData);
    if (data.status || status === 200) {
      return { status: data.status, message: data.message }
    }
  } catch (err: any) {
    return { status: false, message: err?.response?.data?.message || err?.message }
  }
};
export const deleteCohortMilestone = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().deleteMilestone(payload);
    if (data.status || status === 200) {
      return { status: data.status, message: data.message }
    }
  } catch (err: any) {
    return { status: false, message: err?.response?.data?.message || err?.message }
  }
};

export const updateTask = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().updateMilestone(payload);
    if (data?.status || status === 200) {
      dispatch(
        openPopup({
          popupMessage: data?.message,
          popupType: 'success'
        })
      );
      dispatch(changeUpdateTaskModalState({ state: false, task: null }));
      if (payload.teamId) {
        dispatch(getMilestone({ cohortId: payload.cohortId, incubatorId: payload.teamId }))
      } else {
        dispatch(getMilestone({ cohortId: payload.cohortId, incubatorId: payload.incubatorId }))
      }
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};
export const getMilestone = (payload: any) => async (dispatch: any) => {
  const { cohortId, incubatorId, userId, teamId } = payload
  dispatch(globalLoadingStart('milestone'));
  let submitData: { cohortId: string, userId?: string } = {
    cohortId
  }
  if (teamId) {
    submitData = { ...submitData, userId: teamId }
  } else {
    submitData = { ...submitData, userId: incubatorId }
  }
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getMilestone(submitData);
    if (data.status || status === 200) {
      dispatch(getMilestoneDataSuccess(data?.data || []));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(globalLoadingEnd());
  }
}
export const cohortResources = ({ cohortId }: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getResources({ cohortId, docCategory: 'resources' });
    console.log(data, 'dataaaaaaaaaaaaaa', status);

    if (data?.data?.objectUrls?.length > 0 || status === 200) {
      dispatch(cohortResourcesSuccess(data?.data?.objectUrls || []));
    } else {
      dispatch(cohortResourcesSuccess([]));
    }
  } catch (err: any) {
    console.log(err);
  } finally {
    dispatch(stopLoading());
  }
}
export const createCohortInvestment = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createInvestment(payload);
    if (data?.status || status === 200) {
      return { status: true, message: data.message }
    }
  } catch (err: any) {
    return { status: false, message: err?.response?.data?.message || err?.message }
  }
}
export const cohortInvestment = ({ cohortId }: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getInvestment({ cohortId });
    if (data?.status || status === 200) {
      dispatch(investmentTrackingSuccess(data?.data));
    } else {
      dispatch(investmentTrackingSuccess([]));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(stopLoading());
  }
}
export const cohortAssignedMentor = ({ cohortId }: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().assignedMentorList({ cohortId });
    if (status === 200) {
      dispatch(assinedMentorSuccess(data?.data));
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
    return { status: false }
  } finally {
    dispatch(stopLoading());
  }

  // const path = `cohort/mentor/get?cohortId=${cohortId}`
  // const response = await httpRequest(path, 'GET', null, 'json', 'cohort')
  // if (response?.status) {
  //   dispatch(assinedMentorSuccess(response?.data));
  // } else {
  //   dispatch(assinedMentorSuccess([]));
  // }
  // dispatch(stopLoading());
}
export const getTeamCohort = ({ userId }: { userId: string }) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getMyCohort({ userId });
    if (data?.status || status === 200) {
      dispatch(viewUserCohortSuccess(data?.data?.[0]));
    } else {
      dispatch(viewUserCohortSuccess(null));
    }
  } catch (err: any) {
    console.log(err.message);
  } finally {
    dispatch(stopLoading());
  }
}
export const getReports = ({ teamId, cohortId }: { teamId: string, cohortId: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('reports'))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getResources({ cohortId, teamId, docCategory: 'reports' });
    if (data?.data?.objectUrls?.length > 0 || status === 200) {
      dispatch(getReportsSuccess(data?.data?.objectUrls || []));
    } else {
      dispatch(getReportsSuccess([]));
    }
  } catch (err: any) {
    console.log(err, 'errr');
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const delteReport = ({ key }: { key: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart(key))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().deleteResources({ key });
    if (status === 200) {
      return { status: true, message: data.message }
    } else {
      throw new Error(data.message)
    }
  } catch (err: any) {
    return { status: false, message: err?.response?.data?.message || err?.message }
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const delteResource = ({ key }: { key: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart(key))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().deleteResources({ key });
    if (status === 200) {
      return { status: true, message: data.message }
    } else {
      throw new Error(data.message)
    }
  } catch (err: any) {
    return { status: false, message: err?.response?.data?.message || err?.message }
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const checkCohortEligibility = ({ incubatorId }: { incubatorId: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('cohort'));

  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().checkCohortPermission({ incubatorId });
    dispatch(checkCohortAccess(data.status))

    if (data.status) {
      dispatch(cohortList({ incubatorId }))
    } else {
      dispatch(
        openPopup({
          popupMessage: "You don't have access to view cohort please contact admin",
          popupType: 'error'
        })
      )
      dispatch(globalLoadingEnd())
    }
  } catch (err: any) {
    console.log(err, 'error');

    if (err.code === 'ERR_NETWORK') {
      try {
        const { status, data }: APIResponse<any> =
          await new CoohortApiServices().checkCohortPermission({ incubatorId });
        dispatch(checkCohortAccess(data.status))
        if (data.status) {
          dispatch(cohortList({ incubatorId }))
        } else {
          dispatch(checkCohortAccess(false))
          dispatch(
            openPopup({
              popupMessage: "You don't have access to view cohort please contact admin",
              popupType: 'error'
            })
          )
          dispatch(globalLoadingEnd())
        }
      } catch (err: any) {
        dispatch(globalLoadingEnd())
      }
    } else {
      dispatch(globalLoadingEnd())
      dispatch(checkCohortAccess(false))
    }
  }
}

export const createEvents = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('event'))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createEvents(payload);
    if (data.status || status === 200) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success',
          navigateTo: -1
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getEvents = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  dispatch(getEventSuccess([]))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getEvents({ cohortId: payload.cohortId, incubatorId: payload.incubatorId });
    if (data.status || status === 200) {
      dispatch(getEventSuccess(data.data))
    } else {
      dispatch(getEventSuccess([]))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
    if (err.code === 'ERR_NETWORK') {
      dispatch(startLoading());
      try {
        const { status, data }: APIResponse<any> =
          await new CoohortApiServices().getEvents({ cohortId: payload.cohortId, incubatorId: payload.incubatorId });
        if (data.status || status === 200) {
          dispatch(getEventSuccess(data.data))
        } else {
          dispatch(getEventSuccess([]))
        }
      } catch (err: any) {
        console.log(err.message, 'err');
      }
    }
  } finally {
    dispatch(stopLoading())
  }
}

export const getViewEvent = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  dispatch(viewEventSuccess(null))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getEvents({ cohortId: payload.cohortId, incubatorId: payload.incubatorId, id: payload.id });
    if (data.status || status === 200) {
      dispatch(viewEventSuccess(data.data?.[0] || null))
    } else {
      dispatch(viewEventSuccess(null))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
    if (err.code === 'ERR_NETWORK') {
      dispatch(startLoading());
      try {
        const { status, data }: APIResponse<any> =
          await new CoohortApiServices().getEvents({ cohortId: payload.cohortId, incubatorId: payload.incubatorId, id: payload.id });
        if (data.status || status === 200) {
          dispatch(viewEventSuccess(data.data?.[0] || null))
        } else {
          dispatch(viewEventSuccess(null))
        }
      } catch (err: any) {
        console.log(err.message, 'err');
      }
    }
  } finally {
    dispatch(stopLoading())
  }
}
export const createCohortSurvey = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('survey'))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().createSurvey(payload);
    if (data.status || status === 200) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success',
          navigateTo: -1
        })
      );
    }
  } catch (err: any) {
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || err?.message,
        popupType: 'error'
      })
    );
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getServey = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading())
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getSurvey({ cohortId: payload.cohortId, incubatorId: payload.incubatorId });
    if (data.status || status === 200) {
      dispatch(getSurveySuccess(data.data))
    } else {
      dispatch(getSurveySuccess([]))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
    if (err.code === 'ERR_NETWORK') {
      dispatch(startLoading())
      try {
        const { status, data }: APIResponse<any> =
          await new CoohortApiServices().getSurvey({ cohortId: payload.cohortId, incubatorId: payload.incubatorId });
        if (data.status || status === 200) {
          dispatch(getSurveySuccess(data.data))
        } else {
          dispatch(getSurveySuccess([]))
        }
      } catch (err: any) {
        console.log(err.message, 'err');
      }
    }
  } finally {
    dispatch(stopLoading())
  }
}
export const getPublicServey = ({ id }: { id: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('response'))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getPublicSurveyForm({ id });
    if (data.status || status === 200) {
      dispatch(getPublicSurveySuccess(data.data || null))
    } else {
      dispatch(getPublicSurveySuccess(null))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const getPublicEvent = ({ id }: { id: string }) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('response'))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getPublicEvent({ id });
    if (data.status || status === 200) {
      dispatch(getPublicEventSuccess(data.data || null))
    } else {
      dispatch(getPublicEventSuccess(null))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getViewSurvey = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading())
  dispatch(viewSurveySuccess(null))
  try {
    const { status, data }: APIResponse<any> =
      await new CoohortApiServices().getSurvey({ cohortId: payload.cohortId, incubatorId: payload.incubatorId, id: payload.id });
    if (data.status || status === 200) {
      dispatch(viewSurveySuccess(data.data?.[0] || null))
    } else {
      dispatch(viewSurveySuccess(null))
    }
  } catch (err: any) {
    console.log(err.message, 'err');
    if (err.code === 'ERR_NETWORK') {
      dispatch(startLoading())
      try {
        const { status, data }: APIResponse<any> =
          await new CoohortApiServices().getSurvey({ cohortId: payload.cohortId, incubatorId: payload.incubatorId, id: payload.id });
        if (data.status || status === 200) {
          dispatch(viewSurveySuccess(data.data?.[0] || null))
        } else {
          dispatch(viewSurveySuccess(null))
        }
      } catch (err: any) {
        console.log(err.message, 'err');
      }
    }
  } finally {
    dispatch(stopLoading())
  }
}

export const createSurveyResponse = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(startLoading())
  try {
    const response: APIResponse<any> =
      await new CoohortApiServices().createSurveyResponse(payload);
    return response
  } catch (err: any) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading())
  }
}
export const getUploadMedia =
  (payload: TUploadApplicationFileAction) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    const { data: fileData, ...uploadPayload } = payload;

    try {
      const response: APIResponse<TUploadApplicationFileResponse> =
        await new CoohortApiServices().uploadApplicationFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };
        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
      try {
        const response: APIResponse<TUploadApplicationFileResponse> =
          await new CoohortApiServices().uploadApplicationFile(uploadPayload);

        const { status, data } = response;
        if (status === 200 && data) {
          const uploadFileToS3Payload: TUploadFileToS3Request = {
            s3Key: data?.data || '',
            data: fileData,
            includeAuthorizationHeaders: false
          };

          dispatch(uploadFileToS3(uploadFileToS3Payload));
        }

        return response;
      } catch {
      }
    } finally {
      dispatch(stopLoading());
    }
  };

export const getSurveyResponse = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('response'))
  dispatch(viewSurveyResponseSuccess([]))
  const { incubatorId, serveyId } = payload
  try {
    const { data, status }: APIResponse<any> =
      await new CoohortApiServices().getPublicSurveyResponse({ incubatorId, serveyId });
    if (data.status || status === 200) {
      dispatch(viewSurveyResponseSuccess(data.data))
    } else {
      dispatch(viewSurveyResponseSuccess([]))
    }
  } catch (err: any) {
    console.log(err.message, 'errr');
    if (err.code === 'ERR_NETWORK') {
      dispatch(globalLoadingStart('response'))
      try {
        const { data, status }: APIResponse<any> =
          await new CoohortApiServices().getPublicSurveyResponse({ incubatorId, serveyId });
        if (data.status || status === 200) {
          dispatch(viewSurveyResponseSuccess(data.data))
        } else {
          dispatch(viewSurveyResponseSuccess([]))
        }
      } catch (err: any) {
        console.log(err.message, 'errr');
      }
    }
  } finally {
    dispatch(globalLoadingEnd())
  }
}
